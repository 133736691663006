<template>
  <div id="index">
    </div>
</template>

  
<script>
  export default {
    name: "People-index",
    components: {
    }
  }
</script>
  
<style scoped>
  
</style>